.woocommerce-account,
.woocommerce-checkout{
  // .button[name="register"],
  // .button[name="login"]{
  //   @include wooButtonDefault();
  // }
  h3{
    &:not(.tk-freight-big-pro){
      @include font-size-resp(30,1,0);
      margin-bottom: 20px;
    }
  }
  .lost_password{
    a{
      color: $light-grey;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  a:not(.btn-default):not(.footer-link){
    color: $brown;
    font-weight: 500;
    @include font-size(18,20,0);
    &:hover{
      text-decoration: underline;
    }
  }
  .woocommerce-privacy-policy-link{
    color: $light-grey;
    &:hover{
      text-decoration: underline;
    }
  }
  .woocommerce-MyAccount-content{
    width: 100%;
    float: none;
    background: $white;
    padding: 15px 5px;
    top:-1px;
    @include media-breakpoint-up(sm){
      padding: 30px;
    }
  }
  .tgwc-woocommerce-MyAccount-navigation-wrap{
    margin-bottom: -1px !important;
    .scroll_tabs_container{
      background: $antiquewhite;
      .scroll_tab_right_button,.scroll_tab_left_button{
        background: $red !important;
        z-index: 1;
        &:before{
          border:2px solid $white !important;
          border-left: transparent !important;
          border-bottom: transparent !important;
        }
      }
      .scroll_tab_inner{
        li{
          @include font-size(16,22,0);
          a{
            padding: 8px 15px;
          }
        }
      }
      .woocommerce-MyAccount-navigation-link{
        a{
          color: $black;
        }
        &.is-active{
          background-color: $white !important;
          a{
            color: $salmon;
          }
        }
      }
    }
  }
}

.woocommerce .woocommerce-form-login{
  .woocommerce-form-login__submit{
    float: none;
    margin-right: 0;
  }
  .woocommerce-form-login__rememberme{
    margin-bottom: 0;
  }
}

.woocommerce-lost-password{
  form.woocommerce-ResetPassword{
    padding: 15px 5px;
    @include media-breakpoint-up(sm){
      padding: 30px;
    }
  }
}

.woocommerce-my-coupons{
  .invalid-coupon{
    background-color: $indianred !important;
  }
}
