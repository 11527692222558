figure{
  margin: 0;
  figcaption{
    @include font-size-resp(17,1,0);
    margin-top: 10px;
    color: $black;
  }
}

.content{
  p,ul{
    @include font-size-resp(19,1.2,0);
  }
  h3{
    font-family: $font-family-alt;
    font-weight: 900;
    @include font-size-resp(60,.9,0);
    margin-bottom: 20px;
    @include media-breakpoint-up(lg){
      margin-bottom: 40px;
    }
  }
  h4{
    font-family: $font-family-alt;
    font-weight: 900;
    @include font-size-resp(40,.9,0);
    margin-bottom: 15px;
  }
  h2{
    font-family: $font-family-alt;
    font-weight: 900;
    @include font-size-resp(40,1.1,0);
    margin-bottom: 15px;
  }
}

// .content-right .content {
//   a,p,h1,h2,h3,h4,h5,h6,ul,ol{
//     @include make-col-ready();
//     @include media-breakpoint-up(lg) {
//       @include make-col(11);
//       @include make-col-offset(1);
//     }
//   }
// }
//
// .content-left .content {
//   p,a{
//     @include make-col-ready();
//     @include media-breakpoint-up(lg) {
//       @include make-col(10);
//     }
//   }
//   h1,h2,h3,h4,h5,h6,ul,ol{
//     @include make-col-ready();
//     @include media-breakpoint-up(lg) {
//       @include make-col(12);
//     }
//   }
// }

// .module-list{
  // .content-left .content {
  //   h1,h2,h3,h4,h5,h6,ul,ol{
  //     @include media-breakpoint-up(lg) {
  //       @include make-col(10);
  //     }
  //   }
  // }
  ul.list{
    list-style-image: url('#{$img-path}/list-arrow.svg');
  }
// }

// .content-left .content,
// .content-right .content {
//   h3{
//     @include media-breakpoint-down(md){
//       margin-bottom: 30px;
//     }
//   }
// }

.module-actions{
  .masonry-grid{
    .grid-item,
    .grid-sizer{
      width: 47%;
      @include media-breakpoint-down(sm){
        width: 100%;
      }
    }
    .gutter-sizer { width: 3%; }
  }
}

.pre-module-dates{
  @include gradient(0,$antiquewhite,$white);
}
.module-dates {
  .row.no-gutters{

    .card{
      .card-body{
        padding: 0;
        @include media-breakpoint-up(md){
          padding: 30px 0;
        }
        .label{
          &:after{
            content: '';
            position: absolute;
            width: 40px;
            background-color: $brown;
            height: 3px;
            bottom: -10px;
            left:0;
            @include media-breakpoint-up(lg){
              width: 82px;
            }
          }
        }
      }
      .image{
        position: relative;
        z-index: 1;
      }
    }

    .card{
      background: transparent;
      border: none;
      border-radius: 0;
      top:8px;
      .text{
        @include media-breakpoint-down(lg) {
          position: relative;
        }
      }
    }

    .dot{
      &:before{
        content: '';
        position: absolute;
        width: 1px;
        background-color: #0e0e0e;
        top: 0;
        bottom: 0;
        left: 50%;
      }
    }

  }
}

.module-text-photo {
  // @include make-sticky();
}

.module-quote{
  hr{
    margin-top: 0;
    margin-bottom: 55px;
    border-top: 2px solid $antiquewhite;
    @include media-breakpoint-down(md){
      margin-bottom: 30px;
    }
  }
}

.module-full-width{
  .img-to-scale{
    overflow: hidden;
    img{
      @include transition(transform 5s);
    }
    &.active{
      img{
        @include scale(1.2);
      }
    }
  }
}

.module-key-figures{
  .wrapper-picto{
    min-height: 135px;
  }
}

.module-brands,
.module-castles {
  margin-bottom: 2rem;

  .accordion {
    margin-inline: auto;
    max-width: 570px;
  }

  .bloc-title {
    color: var(--dark-grey);
    font-family: $font-family-alt;
    font-size: 32px;
    font-weight: 700 ;
    line-height: 42px;
    margin-block: 2rem;
    text-align: center;
  }

  .brands-title {
    color: var(--dark-grey);
    font-family: $font-family-alt;
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
  }

  .card {
    background-color: transparent;
    border: none;
    padding-inline: 1rem;
    padding-top: 1rem;

    &:has(.show) {
      background-color: var(--accordion-background-color);
      border-top-right-radius: 30px;

      button {
        color: var(--main-color);
      }
    }
  }

  .wine-category {
    color: var(--wine-category-color);
    font-size: 15px;
    font-weight: 500;
  }

  .card-header  {
    background-color: transparent;
    border-bottom: 0;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;

    h3 {
      align-items: center;
      border-bottom: 1px solid #E4E4E4;
      display: flex;
      justify-content: space-between;
      padding-bottom: 1rem;
    }

    button {
      align-items: center;
      color: var(--dark-grey);
      display: flex;
      font-family: $font-family-alt;
      font-size: 28px;
      font-style: italic;
      font-weight: 600;
      height: unset;
      justify-content: space-between;
      line-height: 28px;
      padding: 0;
      text-decoration: none;
      text-transform: unset;
      text-wrap: balance;

      svg {
        height: 7px;
        transition: all .3s ease;
        width: 14px;
      }

      &[aria-expanded="false"] {
        svg {
          color: #373636;
        }
      }

      &[aria-expanded="true"] {
        svg {
          color: var(--main-color);
          transform: rotate(180deg);
        }
      }

    }
  }

  .brand-img {
    aspect-ratio: 595 / 380;
    border-radius: 50px;
    border-top-left-radius: 0;
    height: auto;
    margin-bottom: 2rem;
    width: 100%;

    @include media-breakpoint-up(lg) {
      margin-bottom: unset;
    }
  }

  .wine-name {
    color: var(--main-color);
    font-family: $font-family-alt;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .wine-description {
    font-size: 1rem;
    font-weight: 500;
  }

  .wine-content-wrapper {
    @include media-breakpoint-up(lg) {
      background-color: var(--accordion-background-color);
      margin-inline: auto;
      margin-top: -75px;
      max-width: 500px;
      padding-left: 2rem;
      padding-top: 2rem;
    }
  }

  .discover-btn {
    align-items: center;
    background-color: #373636;
    color: #fff;
    column-gap: 12px;
    display: flex;
    font-weight: 500;
    justify-content: center;
    margin-inline: auto;
    margin-top: 2rem;
    padding: 1rem 2rem;
    text-transform: uppercase;
    width: 175px;

    svg {
      height: 10px;
      width: 10px;
    }

    @include media-breakpoint-up(lg) {
      margin-right: 0;
    }
  }

  .card-body {
    padding-inline: 0;
    padding-block: 2rem;
  }

  .brands-grid {
    column-gap: 50px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-block: 140px 150px;

    .brands-title {
      margin-bottom: 2rem;
      padding-left: 1rem;
    }

    ul {
      list-style: none;
      padding-left: 0;
    }

    .brand-item {
      border-bottom: 1px solid #E4E4E4;
      border-top-right-radius: 30px;
      display: flex;
      flex-direction: column;
      padding-block: 24px 18px;
      padding-inline: 1rem;

      .wine-name {
        align-items: center;
        color: var(--dark-grey);
        display: flex;
        font-family: $font-family-alt;
        font-size: 28px;
        font-style: italic;
        font-weight: 600;
        justify-content: space-between;
      }

      svg {
        height: 7px;
        transform: rotate(-90deg);
        width: 14px;
      }

      &.is-active {
        background-color: var(--accordion-background-color);

        .wine-name {
          color: var(--main-color);
        }
      }
    }

    .brands-detail {
      left: 0;
      // opacity: 0;
      position: absolute;
      top: 0;
    }
  }
}

.module-castles {
  @include media-breakpoint-up(lg) {
    background-color: $antiquewhite;
    padding-block: 115px 340px;
  }

  &::after {
    content: none;

    @include media-breakpoint-up(lg) {
      content: "";
      background-color: $white;
    }
  }

  .bloc-title {
    margin-top: 4rem;
  }

  .castles-grid {
    @include media-breakpoint-up(lg) {
      column-gap: 30px;
      display: grid;
      grid-template-columns: 500px 1fr;
    }

    @include media-breakpoint-up(xl) {
      column-gap: 90px;
    }
  }

  .castles-content {
    @include media-breakpoint-up(lg) {
      order: -1;
    }
  }

  .card {
    &:has(.show) {
      background-color: #F3F0EA;
    }
  }

  .swiper-container {
    overflow: unset;
    padding-right: 30px;

    @include media-breakpoint-up(lg) {
      overflow: hidden;
    }
  }

  .swiper-slide {
    align-items: center;
    background: linear-gradient(var(--main-color) 50%, #ECE6D6 50%);
    border-radius: 50px 50px 0 50px;
    display: flex;
    flex-direction: column;
    height: auto;
    padding-block: 1rem 3rem;

    @include media-breakpoint-up(lg) {
      background: linear-gradient(var(--main-color) 50%, #F3F0EA 50%);
    }

    .discover-btn {
      margin-top: 0;
      width: 210px;

      @include media-breakpoint-up(lg) {
        margin-right: auto;
      }
    }
  }

  .swiper-pagination-bullet {
    background: var(--main-color);
    height: 13px;
    transition: all .3s ease;
    width: 13px;
  }

  .swiper-pagination-bullet-active {
    height: 16px;
    width: 16px;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    background-color: #FFFFFF;
    border-radius: 0 20px 20px 20px;
    font-size: 20px;
    padding: 18px 24px;
  }

  .swiper-button-next {
    @include media-breakpoint-up(lg) {
      right: 20px;
      top: 57%;
    }
  }

  .swiper-button-prev {
    @include media-breakpoint-up(lg) {
      left: unset;
      right: 20px;
      top: 70%;
    }
  }

  .bottle-img {
    margin-bottom: 1.5rem;
  }

  .castle-name {
    color: var(--dark-grey);
    font-family: unset;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  .bottle-infos {
    border-top: 1px solid #D8D2C3;
    color: var(--dark-grey);
    padding-block: 1rem 1.5rem;
    text-align: center;
    text-transform: uppercase;
    width: 250px;
  }

  .castle-item {
    border-bottom: 1px solid #C8C2B4;
    border-top-right-radius: 30px;
    display: flex;
    flex-direction: column;
    padding-block: 24px 18px;
    padding-inline: 1rem;

    .wine-category {
      @include media-breakpoint-up(lg) {
        text-align: end;
      }
    }

    .wine-name {
      align-items: center;
      color: var(--dark-grey);
      display: flex;
      font-family: $font-family-alt;
      font-size: 28px;
      font-style: italic;
      font-weight: 600;
      justify-content: space-between;

      @include media-breakpoint-up(lg) {
        flex-direction: row-reverse;
        text-align: end;
      }
    }

    svg {
      height: 7px;
      transform: rotate(-90deg);
      width: 14px;

      @include media-breakpoint-up(lg) {
        transform: rotate(90deg);
      }
    }

    &.is-active {
      background-color: var(--accordion-background-color);

      @include media-breakpoint-up(lg) {
        background-color: #F3F0EA;

      }

      .wine-name {
        color: var(--main-color);
      }
    }
  }

}
