:root {
  --accordion-background-color: #F6F6F6;
  --dark-grey: #3F3E3E;
  --main-color: #BC5D4D;
  --swiper-theme-color: $black;
  --swiper-navigation-size: 35px;
  --wine-category-color: #7C7C7C;
}

* {
  box-sizing:  border-box;
  position: relative;
  margin: 0;
  padding: 0;
}

body {
  font-family: $default-font-family;
  @include font-size(17,20,0);
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
  padding-top: 71px;

  @include media-breakpoint-only(md){
    padding-top: 81px;
  }

  @include media-breakpoint-up(lg){
    padding-top: 126px;
  }

  &.no-scroll{
    overflow: hidden;
  }

  &.locked-filters {
    .fixed-filters {

      @include media-breakpoint-up(lg) {
        position: fixed;
        width: 100%;
        left: 0;
        right: 0;
        z-index: 10;
        // top: 64px;
        top: 0;
        max-width: 1200px;
        margin: 0 auto;
        background: $white;
        padding-bottom: 10px;
        padding-top: 10px;
        // fieldset{
        //   margin-bottom: 0 !important;
        // }
      }

    }
  }
}

a,button, span {
  &:focus,
  &:hover,
  &:active{
    outline: none;
    text-decoration: none;
  }
}

ul,ol {
  list-style-position: inside;
  padding: 0;
}

a {
  &.fake-tab {
    color: $indianred;
    @include media-breakpoint-up(md){
      color: rgba($tan,.5);
    }
    @include transition(color $delay);
    @include media-breakpoint-down(sm){
      @include font-size(20,60,0);
      width: 50%;
      text-align: center;
    }

    &.active {
      color: rgba($tan,1);
      @include media-breakpoint-down(sm){
        background: $indianred;
      }

      &:after {
        @include media-breakpoint-up(md){
          content: '';
          position: absolute;
          bottom:    5px;
          width: 100%;
          left:0;
          border-top: solid 2px $tan;
        }
      }
    }
    &:hover{
      color: rgba($tan,1);
    }
  }
}

.services-pro-nav {
  @include media-breakpoint-down(sm){
    background: $antiquewhite;
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
    bottom:0;

    &+.page-header-content{
      &+.single-share{
        display: none !important;
      }
    }

    .container{
      padding: 0;

      .col-12{
        padding: 0;
      }
    }
  }
}

.contact-content {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5rem !important;

  @include media-breakpoint-up(xl) {
    font-size: 40px;
    line-height: 40px !important;
  }
}
