.woocommerce-checkout{
  form.woocommerce-checkout{
    padding: 15px 5px;
    @include media-breakpoint-up(sm){
      padding: 30px;
    }
  }
  hr{
    border-top: 1px solid $light-grey;
    margin: 30px 0;
  }
  .showcoupon{
    color: $light-grey;
    &:hover{
      text-decoration: underline;
    }
  }
  textarea{
    &.input-text{
      min-height: 150px;
    }
  }
  #payment{
    border-radius: 0;
    background: $white;
  }
  &.woocommerce-order-received{
    .woocommerce{
      background: $antiquewhite;
      padding: 15px 5px;
      @include media-breakpoint-up(sm){
        padding: 30px;
      }
      h2{
        margin-bottom: 20px;
      }
      ul.order_details{
        li{
          @include media-breakpoint-down(md){
            float:none;
            border-right: 0;
            padding-right: 0;
            margin-right: 0;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
