.nos-vins,
.nos-vins-casher,
.page-id-9281 {
  header,
  .navbar,
  #breadcrumbs,
  .page-header-title {
    background-color: var(--main-color);
  }

  .page-header.wave-3:before {
    background: var(--main-color);
  }

  .text-center h1 {
    font-size: 36px;
    margin-bottom: 30px ;

    @include media-breakpoint-up(lg) {
      font-size: 55px;
    }
  }

  .page-header {
    img {
      border-radius: 18px 0 18px 18px;
    }
  }

  .page-nav-wave,
  .services-pro-nav {
    display: none;
  }

  .wave-3 {
    @include media-breakpoint-up(md){
      background: url('#{$img-path}/wave-indianred.svg') center center repeat-x;
    }

    &:before{
      background: $indianred;
    }
  }

  .section-contact {
    &::after {
      background-color: $indianred;
      mask-size: cover;

      @include media-breakpoint-up(sm) {
        content: none;
      }

      @include media-breakpoint-up(lg) {
        content: '';
        height: 300px;
      }
    }

    .btn-bg-black {
      column-gap: 12px;
      justify-content: center !important;
    }
  }
}
