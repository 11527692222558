.borderified{
  border: solid 1px $white;
  h3,h4{
    padding: 20px;
    @include media-breakpoint-up(lg){
      padding: 30px;
    }
  }
  .content{
    p{
      @include font-size-resp(20,1.1,0);
      line-height: 28px;
    }
    padding: 20px;
    @include media-breakpoint-up(lg){
      padding: 30px;
    }
  }
  .sep{
    background: $white;
    height: 1px;
  }
  &.alt{
    border: solid 1px #525F2A;
    .sep{
      background: #525F2A;
    }
  }
}

.title-1-wrapper{
  h1,h3{
    @include media-breakpoint-up(lg){
      padding-bottom: 30px;
    }
    border-bottom: solid 1px;
  }
  &.white{
    h1,h3{
      color: $white;
      border-color: $white;
    }
    &+.rotating-text{
      svg{
        fill: $white;
      }
    }
  }
  &.tan{
    h1,h3{
      color: $tan;
      border-color: $tan;
    }
    &+.rotating-text{
      svg{
        fill: $tan;
      }
    }
  }
  &.blackk{
    h1,h3{
      color: $black;
      border-color: $black;
    }
    &+.rotating-text{
      svg{
        fill: $black;
      }
    }
  }
}

.rotating-text{
  svg{
    width: 70px;
    height: 70px;
    @include media-breakpoint-only(md){
      width: 100px;
      height: 100px;
    }
    @include media-breakpoint-up(lg){
      width: 140px;
      height: 140px;
    }
    @include media-breakpoint-up(xl){
      width: 160px;
      height: 160px;
    }
  }
  .need-rotate{
    position: absolute;
    animation: rotate-text 15s linear infinite;
  }
}

@keyframes rotate-text{
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.title-curved{
  img{
    @include transition(transform $delay);
  }
  .mask{
    background: $black;
    opacity: .25;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    max-width: 370px;
  }
  &.no-curved{
    .mask{
      max-width: 100%;
    }
  }
  &:not(.no-curved){
    .img-wrapper{
      border-radius: 80px;
    }
  }
  .title{
    border: solid 2px $white;
    color: $white;
    &:not(.no-abs){
      @include both-align();
      width: 80%;
      z-index: 3;
    }
    @include media-breakpoint-up(lg){
      line-height: 33px;
    }
  }
  &:not(.no-curved){
    &:nth-child(odd){
      .img-wrapper{
        border-top-right-radius: 0;
      }
    }
    &:nth-child(even){
      .img-wrapper{
        border-bottom-left-radius: 0;
      }
    }
  }
  a{
    &:hover{
      img{
        @include scale(1.1);
      }
    }
  }
}

.product-card{
  color: $black;
  @include transition(color $delay);
  h3{
    @include media-breakpoint-up(md){
      min-height: 62px;
    }
  }
  &.bg-product-card-29{
    .product-card-image-wrapper{
      &:before{
        @include gradient(90deg,$indianred,$antiquewhite);
      }
    }
  }
  &.bg-product-card-30{
    .product-card-image-wrapper{
      &:before{
        @include gradient(90deg,$darkseagreen,$antiquewhite);
      }
    }
  }
  &.bg-product-card-31{
    .product-card-image-wrapper{
      &:before{
        @include gradient(90deg,$salmon,$antiquewhite);
      }
    }
  }
  &.bg-product-card-66{
    .product-card-image-wrapper{
      &:before{
        @include gradient(90deg,$darkseagreen,$antiquewhite);
      }
    }
  }
  .product-card-image-wrapper{
    min-height: 220px;
    @include media-breakpoint-up(md){
      min-height: 440px;
    }
    img.product-image{
      @include transition(transform $delay);
      @include scale(.95);
      @include media-breakpoint-down(sm){
        max-height: 220px;
        width: auto;
      }
    }
    &:before{
      content: "";
      border-radius: 60px;
      border-top-right-radius: 0;
      height: 75%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 13%;
      @include transition(transform $delay);
      @include media-breakpoint-down(sm){
        border-radius: 30px;
        border-top-right-radius: 0;
        height: 83%;
        top:8%;
      }
    }
    &.no-thumbnail{
      .logo-univitis{
        width: 117px;
        height: 35px;
        @include media-breakpoint-up(md){
          width: 217px;
          height: 61px;
        }
      }
    }
  }

  &:hover{
    color: $salmon;
    .product-card-image-wrapper{
      // &:not(.no-thumbnail){
        img.product-image{
          @include scale(1);
        }
        &:before{
          @include media-breakpoint-up(lg){
            @include scale(1.05);
          }
        }
      // }
    }
    hr{
      border-color: $salmon;
    }
  }


  .medals-labels{
    position: absolute;
    top: 30px;
    right: 5px;
    @include media-breakpoint-up(md){
      right: 10px;
      top: 72px;
    }
    img{
      margin-bottom: 5px;
      // mix-blend-mode: multiply;
    }
  }
  hr{
    max-width: 230px;
    border-top: 1px solid $black;
    @include transition(border $delay);
  }
}

.lines{
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  grid-gap: 1rem;

  &:before,
  &:after {
    content: "";
    display: block;
    border-top: 2px solid currentColor;
  }

}

// .page-header-content{
//   p{
//     margin-bottom: 0;
//   }
// }

.article-card{
  border: none;
  border-radius: 0;
  .card-img-top{
    border: none;
    border-radius: 0;
  }
  .card-body{
    padding: 25px;
  }
  .card-footer{
    border: none;
    border-radius: 0;
    background: $white;
    padding-bottom: 25px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .content{
    &:not(.with-margin){
      p{
        margin-bottom: 0;
      }
    }
  }
}

.nobr-under-t{
  @include media-breakpoint-down(md){
    br{
      display: none;
    }
  }
}

.curved{
  border-radius: 50px;
  border-top-right-radius: 0;
  h3{
    &:after{
      content: '';
      position: absolute;
      width: 100%;
      background-color: $white;
      height: 2px;
      bottom: -10px;
      left:0;
    }
  }
}

.card-range{
  border-radius: 50px 0px 50px 50px;
  height: 100%;
  .wpgb-card-wrapper{
    height: 100%;
    .wpgb-card-inner{
      height: 100%;
      .row{
        height: 100%;

      }
    }
  }
  hr{
    border-top: 1px solid $black;
    margin: 20px 0;
  }
  .bg{
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;
    border-radius: 0px 0px 50px 0px;
  }
}

.quantity_wrapper,
.product-quantity{
  .quantity{
    display: -ms-flexbox;
    display: flex;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
    input[type="number"]{
      @include font-size-resp(20,1,0);
      font-weight: 500;
      height: 30px;
      border: solid 1px $dimgray;
      width: 40px;
      padding: 0 3px;
    }
  }
  .more-less{
    height: 24px;
    margin-top: 3px;
    margin-left: 10px;
    button{
      background-color: transparent;
      border: none;
      background: url('#{$img-path}/up-down.svg') center center no-repeat;
      width:12px;
      height: 7px;
      &.minus{
        @include rotate(180deg);
        &:hover{
          @include transform(scale(1.1) rotate(180deg));
        }
      }
      &:hover{
        @include scale(1.1);
      }
    }
  }
}

div.mask{
  top: 0;
  right: 0;
  background: rgba($white,.7);
  z-index: 10;
  position: fixed;
  left: 0;
  bottom: 0;
  display: none;
}

#breadcrumbs{
  a,span{
    @include font-size(15,0,18);
    @include media-breakpoint-up(md){
      @include font-size(17,0,20);
    }
    color:$white;
  }
  .breadcrumb_last{
    color: $white;
  }
  a{
    &:hover{
      text-decoration: underline;
    }
  }
}

.sep-black{
  border-bottom: solid 1px $black;
}

.job-card{
  .view-offer{
    svg{
      @include transition(fill $delay);
    }
    &:hover{
      svg{
        fill: $salmon;
      }
    }
  }
}

section.legal-notices,
.woocommerce-terms-and-conditions{
  font-family: $default-font-family;
  h2{
    @include font-size-resp(26,1.1,0);
    font-weight: 500;
    padding: 20px 0;
  }
  h3{
    @include font-size-resp(22,1.1,0);
    font-weight: 500;
    padding: 20px 0;
  }
  h4{
    @include font-size-resp(20,1,0);
    font-weight: 500;
    padding: 20px 0;
  }
  ol{
    padding-left: 50px;
  }
  a{
    color: $brown;
    &:hover{
      text-decoration: underline;
    }
  }
}

.footer-link{
  &:hover{
    text-decoration: underline;
  }
}
