.wave-1{
  background-color: $antiquewhite;
  &:before{
    content: "";
    width: 100%;
    -webkit-mask:  url('#{$img-path}/wave-1.svg') center top repeat-x;
    mask: url('#{$img-path}/wave-1.svg') center top repeat-x;
    background-color: $darkseagreen;
    position: absolute;
    height: 150px;
    top:0;
    @include media-breakpoint-only(md){
      top:-50px;
    }
    @include media-breakpoint-up(lg){
      top:-1px;
      height: 210px;
    }
  }
  height: 120px;
  @include media-breakpoint-only(md){
    height: 150px;
  }
  @include media-breakpoint-up(lg){
    height: 210px;
  }
}

.wave-3{
  @include media-breakpoint-up(md){
    // background-color: $darkseagreen;
    background: url('#{$img-path}/wave-3.svg') center center repeat-x;
    &:before{
      content: "";
      width: 100%;
      background-color: $darkseagreen;
      position: absolute;
      height: 150px;
      top:0;
    }
    &.has-video{
      &:before{
        height: 180px;
      }
    }
  }
  @include media-breakpoint-down(md){
    background-size: contain;
  }
  @include media-breakpoint-down(sm){
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $darkseagreen;
      clip-path: ellipse(75% 100% at 50% 0%);
      -webkit-clip-path: ellipse(75% 100% at 50% 0);
    }
  }
}

.wave-scale{
  clip-path: ellipse(75% 100% at 50% 0%);
  -webkit-clip-path: ellipse(75% 100% at 50% 0);
  @include media-breakpoint-up(sm){
    -webkit-mask:  url('#{$img-path}/wave-scale.svg') center center repeat-x;
    mask: url('#{$img-path}/wave-scale.svg') center center repeat-x;
    -webkit-mask-size: contain;
    mask-size: contain;
    clip-path: unset;
  }
}

.pro-space,
.section-contact,
.newsletter,
.module-castles,
.single-gammes .page-header {
  &:after{
    content: "";
    width: 100%;
    -webkit-mask:  url('#{$img-path}/wave-mobile.svg') center bottom no-repeat;
    mask: url('#{$img-path}/wave-mobile.svg') center bottom no-repeat;
    background-color: $antiquewhite;
    position: absolute;
    height: 100px;
    bottom:0;

    mask-size: contain;
    -webkit-mask-size: contain;
    @include media-breakpoint-only(md){
      height: 170px;
      bottom:-10px;
    }
    @include media-breakpoint-up(lg){
      -webkit-mask:  url('#{$img-path}/wave-2.svg') center 30px repeat-x;
      mask: url('#{$img-path}/wave-2.svg') center 30px repeat-x;
      height: 210px;
      mask-size: auto;
      -webkit-mask-size: auto;
    }
    @include media-breakpoint-up(xl){
      -webkit-mask:  url('#{$img-path}/wave-2.svg') center top repeat-x;
      mask: url('#{$img-path}/wave-2.svg') center top repeat-x;
    }
  }
}

.page-nav-wave{
  padding-top: 100px;
  @include media-breakpoint-up(lg){
    padding-top: 210px;
  }
  @include media-breakpoint-only(md){
    padding-top: 170px;
  }
  &:before{
    content: "";
    width: 100%;
    -webkit-mask:  url('#{$img-path}/wave-mobile.svg') center bottom no-repeat;
    mask: url('#{$img-path}/wave-mobile.svg') center bottom no-repeat;
    background-color: $antiquewhite;
    position: absolute;
    height: 100px;
    top:0;

    mask-size: contain;
    -webkit-mask-size: contain;
    @include media-breakpoint-only(md){
      height: 170px;
    }
    @include media-breakpoint-up(lg){
      -webkit-mask:  url('#{$img-path}/wave-2.svg') center 30px repeat-x;
      mask: url('#{$img-path}/wave-2.svg') center 30px repeat-x;
      height: 210px;
      mask-size: auto;
      -webkit-mask-size: auto;
    }
    @include media-breakpoint-up(xl){
      -webkit-mask:  url('#{$img-path}/wave-2.svg') center top repeat-x;
      mask: url('#{$img-path}/wave-2.svg') center top repeat-x;
    }
  }
}
