.single-job{
  background: $antiquewhite;
  .page-nav-wave{
    background: $antiquewhite;
    &:before{
      background-color: $white;
    }
  }
  .page-nav{
    background: $white !important;
  }
  .job-details{
    h2{
      border-bottom: solid 1px $black;
    }
  }
  .module-follow-us{
    .bottom{
      @include horizontal-align(absolute);
      bottom: -75px;
    }
    .borderified{
      border:2px solid;
      a{
        color: $black;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
}
