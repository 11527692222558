.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 25px !important;
  padding-bottom: 8px;
  padding-top: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 10px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: $white;
  border: solid 1px $white;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
// .custom-checkbox:hover input ~ .checkmark {
//   background-color: #ccc;
// }

/* When the checkbox is checked, add a blue background */
// .custom-checkbox input:checked ~ .checkmark {
//   background-color: #2196F3;
// }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
  left: 6px;
  top:-1px;
  width: 9px;
  height: 15px;
  border: solid $black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.newsletter-form{
  .no-consent{
    @include media-breakpoint-down(sm){
      max-width: 100%;
    }
  }
  input[type=email]{
    border: 0;
    @include font-size(20,55,0);
    width: 100%;
    padding: 0 15px;
  }
  // .custom-checkbox{
  //   font-style: italic;
  //   font-weight: 300;
  // }
  .content{
    @include font-size(14,22,-10);
    font-weight: 500;
  }
}

.newsletter{
  color: $white;

  .message{
    &.error{
      color: red;
    }
    &.success{
      color: green;
    }
  }
  .btn-subscribe{
    .spinner-border{
      display: none;
    }
    div.svg{
      display: block;
    }
    &.loading{
      .spinner-border{
        display: block;
      }
      div.svg{
        display: none;
      }
    }
  }
}

.newsletter-img {
  aspect-ratio: 855 / 774;
  height: auto;
  object-fit: cover;
  width: 100%;
}

.newsletter-wrapper {
  @include media-breakpoint-up(lg) {
    column-gap: 100px;
    display: flex;
    flex-direction: row-reverse;
  }
}

.christmas-picture {
  overflow: hidden;
  background: url('#{$img-path}/noel_univitis_new.gif') center center/cover;

  @include media-breakpoint-down(xs) {
    background: url('#{$img-path}/christmas-mobile.png') center center/cover;
  }

  @include media-breakpoint-up(sm) {
    width: 573px;
    height: 463px;
  }

  .logo-univitis-popup {
    width: 162px;
    height: 65px;

    @include media-breakpoint-down(sm) {
      z-index: 999;

    }
  }
}

.border-promo-shop-top {
  border-top: 1px solid #EDD9C6;
  z-index: 10;
}
.border-promo-shop-bottom {
  border-bottom: 1px solid #EDD9C6;
  z-index: 10;
}

.mw-340 {
  max-width: 340px;
}


.post-type-archive-product {
  .popin-christmas-home {
    display: none;
  }
}

.form-contact {
  margin-top: 100px;
  padding: 0;
  width: 100%;

  @include media-breakpoint-up(lg) {
    padding: 55px 70px;
  }

  .wpcf7-form {
    width: 100%;
  }

  label {
    font-size: 20px;
    font-weight: 700;
    width: 100%;
  }

  :focus-visible {
    outline: auto 1px;
    outline-color: var(--yellow-600);
  }

  input::file-selector-button {
    background-color: $white;
    border: none;
    border-radius: 6px;
    color: $black;
    cursor: pointer !important;
    font-size: 18px;
    height: 50px;
    margin-right: 20px;
    margin-top: 10px;
    padding-inline: 40px;
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: fit-content;
    }
  }

  input[type='file'] {
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: fit-content;
    }
  }

  .wpcf7 input[type='text'],
  .wpcf7 input[type='tel'],
  .wpcf7 input[type='email'],
  .wpcf7 input[type='number'],
  .wpcf7 .form-select,
  .wpcf7 .wpcf7-select,
  .wpcf7-textarea {
    background-color: $white;
    border: none;
    border-radius: 6px;
    color: $black;
    font-size: 18px;
    height: 50px;
    margin-top: 10px;
    padding-left: 10px;
    width: 100%;
  }

  .wpcf7 input[type='checkbox'] {
    accent-color: var(--yellow-600);
    border: 1px solid #cecece;
    height: 20px;
    margin-top: 0;
    width: 20px;
  }

  .wpcf7-textarea {
    height: 150px;
  }

  .wpcf7-acceptance label {
    font-size: 16px;
    font-weight: normal;
    max-width: 90%;
  }

  .info {
    margin-top: 0;
  }

  .rgpd {
    margin-top: 0;

    .wpcf7-list-item {
      margin: 0;
    }

    .wpcf7-list-item label {
      align-items: flex-start;
      display: flex;
      gap: 10px;
      width: 100%;
    }
  }

  .wpcf7-submit {
    display: flex;
    justify-content: center;
    margin: auto;
    text-align: center;
    width: fit-content;

    margin: 0 auto;
    box-shadow: none;
    font-size: 14px;
    font-size: .875rem;
    line-height: 14px;
    line-height: .875rem;
    letter-spacing: .1em;
    height: 60px;
    padding: 0;
    width: 100%;
    transition: background .3s;
    font-family: "gesta", sans-serif;
    border-color: hsla(0, 0%, 7%, .8);
    border-radius: 0;
    color: #fff;
    background: hsla(0, 0%, 7%, .8);
    text-transform: uppercase;
  }
}
