// Search form
// Search form
.with_frm_style{

  .frm_required{
    display: none;
  }
  .frm_primary_label{
    display: none;
  }
  .frm_compact{
    .frm_primary_label{
      margin-right: 20px;
      padding: 0;
    }
    .frm_dropzone{
      margin: 0;
      min-height: inherit;

      .dz-message{
        -moz-border-radius: 13px !important;
        -webkit-border-radius: 13px !important;
        border-radius: 13px !important;
        border-color: $black !important;
        background: $white !important;
        -webkit-box-shadow: none;
        box-shadow: none;
        button{
          color: $black !important;
        }
        .frm_upload_icon{
          display: none;
        }
      }
    }
  }
}

.frm_style_style-formidable.with_frm_style .frm_submit button{
  margin: 0 auto;
  box-shadow: none;
  @include font-size(14, 14,100);
  // max-width: 270px;
  height: 60px;
  padding: 0;
  width: 100%;
  @include transition(background $delay);
  font-family: $font-family-base;
  border-color: rgba($black, .8);
  border-radius:0;
  color: $white;
  background: rgba($black, .8);
  text-transform: uppercase;
  &:hover {
    background: rgba($black,1);
    border-color: rgba($black,1);
    color: $white;
  }
  &:disabled{
    background: rgba($black, .8);
  }
}

.with_frm_style input[type="checkbox"], .with_frm_style input[type="radio"] {
transform: scale(1.5) !important;
-webkit-transform: scale(1.5) !important;
-moz-transform: scale(1.5);
-ms-transform: scale(1.5);
-o-transform: scale(1.5);
padding-bottom: 5px !important;
}

.with_frm_style input[type=text], .with_frm_style input[type=password], .with_frm_style input[type=email], .with_frm_style input[type=number], .with_frm_style input[type=url], .with_frm_style input[type=tel], .with_frm_style input[type=phone], .with_frm_style input[type=search], .with_frm_style select, .with_frm_style textarea, .frm_form_fields_style, .with_frm_style .frm_scroll_box .frm_opt_container, .frm_form_fields_active_style, .frm_form_fields_error_style, .with_frm_style .frm-card-element.StripeElement, .with_frm_style .chosen-container-multi .chosen-choices, .with_frm_style .chosen-container-single .chosen-single{
  border: none;
  border-radius: 0;
  @include font-size(17,50,0);
  height: 50px;
  background: $white;
  box-shadow: none;
  @include placeholder(){
    color: $black;
    @include font-size(17,50,0);
  }
  &:focus{
    outline: none;
    background-color: $white;
    box-shadow: none;
  }
}
.with_frm_style textarea{
  min-height: 200px;
  color: $black;
  @include font-size(17,20,0);
  @include placeholder(){
    color: $black !important;
    @include font-size(17,20,0);
  }
}
