.woocommerce-shop{
  .wpgb-facet-9{
    display: none;
  }
}

.pro-space,
.section-contact,
.newsletter{
  svg{
    fill: $white;
  }
}

.pro-space,
.section-contact,
.newsletter{
  .rectangle{
    border: 1px solid $white;
  }
  .rotating-text{
    z-index: 1;
    position: absolute;
    top: -20px;
    left: -20px;
    @include media-breakpoint-up(lg){
      top: -80px;
      left: -80px;
    }
  }
  .btn-default{
    z-index: 1;
  }
}

.section-contact{
  &:after{
    background-color: $indianred;
  }
}

.newsletter{
  &:after{
    display: none;
  }
}

.page-header-title{
  background-color: $darkseagreen;
  &:not(.no-mb){
    h1{
      z-index: 1;
      margin-bottom: -20px;
    }
  }
}

.page-template-template-content{
  &.page-id-812,
  &.page-id-3037{
    .page-nav-wave{
      background: $antiquewhite;
      &:before{
        background-color: $white;
      }
    }
    .page-nav{
      background: $white !important;
    }
  }
  .page-nav-wave.alt{
    background: $antiquewhite;
    &:before{
      background-color: $white;
    }
  }
  .page-nav.alt{
    background: $white !important;
  }
}

.page-template-template-cepages{
  .grape-varieties-links{
    .grape-varieties-title{
      overflow: hidden;
      &:after {
        content:"";
        display: inline-block;
        height: 0.5em;
        vertical-align: bottom;
        width: 100%;
        margin-right: -100%;
        margin-left: 10px;
        border-top: 1px solid black;
      }
    }
  }
  .grape-varieties-list{
    figure{
      display: none;
    }

    .bottom-cepage{
      bottom: -35px;
      @include media-breakpoint-up(lg){
        bottom: -73px;
      }
    }
  }
}

.page-template-template-journal,
.category{
  .page-header-title{
    background-color: $salmon;
  }
  background-color: $antiquewhite;
  .page-header{
    // display: block;
    box-sizing: border-box;
    // height: 500px;
    background-color: $salmon;
    clip-path: ellipse(63% 100% at 50% 0%);
  }
  #breadcrumbs,
  .header,
  .header .navbar{
    background-color: $salmon;
  }
  .articles{
    .card{
      @include media-breakpoint-up(lg){
        &.big{
          flex: 0 0 calc(58.3333333333% - 30px);
        }
        &.medium-card{
          flex: 0 0 calc(41.6666666667% - 30px);
        }
      }
    }
  }
}

.page-template-template-content,
.page-template-template-jobs,
.page-template,
.job-template-default{
  &.parent-pageid-830,
  &.parent-pageid-3025,
  &.page-template-template-ourstores,
  &.single-job{
    .wave-3{
      @include media-breakpoint-up(md){
        background: url('#{$img-path}/wave-indianred.svg') center center repeat-x;
      }
      &:before{
        background: $indianred;
      }
    }
    #breadcrumbs,
    .page-header-title,
    .header,
    .header .navbar{
      background-color: $indianred;
    }
    .ranges-gallery-slider{
      .slide-container{
        img{
          @include scale(.95);
          @include media-breakpoint-down(sm){
            max-height: 440px;
            width: auto;
          }
        }
        &:before{
          content: "";
          border-radius: 60px;
          border-top-right-radius: 0;
          height: 69%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 13%;
          @include media-breakpoint-down(sm){
            border-radius: 30px;
            border-top-right-radius: 0;
            height: 73%;
            top:8%;
          }
        }
      }

      .swiper-slide{
        &:nth-child(odd){
          .slide-container{
            &:before{
              @include gradient(90deg,$darkseagreen,$antiquewhite);
            }
          }
        }
        &:nth-child(even){
          .slide-container{
            &:before{
              @include gradient(90deg,$salmon,$antiquewhite);
            }
          }
        }
        hr{
          max-width: 230px;
          border-top: 1px solid $black;
          @include transition(border $delay);
        }
      }

      a{
        color: $black;
        &:hover{
          color: $salmon;
          hr{
            border-color: $salmon;
          }
        }
      }
    }
  }

}

.page-template-template-contact{
  background: $antiquewhite;
  .logo-univitis-contact{
    width: 270px;
    height: 75px;
  }
  .page-header-content{
    p{
      @include media-breakpoint-down(sm){
        br{
          display: none;
        }
      }
    }
  }
}

.page-template-template-jobs{
  background: $antiquewhite;
  .page-nav-wave{
    background: $antiquewhite;
    &:before{
      background-color: $white;
    }
  }
  .page-nav{
    background: $white !important;
  }
  .parity .rectangle{
    border: 2px solid $black;
    svg{
      fill: $salmon;
    }
  }
}
