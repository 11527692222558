.page-template-template-shop{
  &.woocommerce-checkout #payment div.payment_box{
    color: $white;
    background-color: $salmon;
    &:before{
      border-bottom: 1em solid $salmon;
    }
  }
  form{
    .input-text{
      @extend .form-control;
      border-radius: 0 !important;
      border: 1px solid $light-red !important;//$pink-2
      &:focus{
        border: 1px solid $red !important;//$pink
      }
    }
    .form-row{
      label{
        @include font-size(15,22,0);
        color: $black;
      }
      .button{
        @include wooButtonDefault();
      }
    }
    fieldset{
      margin-top: 30px;
      legend{
        @include font-size(16,20,0);
        color: $red;
        text-transform: uppercase;
      }
    }
  }
  &.woocommerce-cart .cart-collaterals .cart_totals table th{
    width: 15%;
  }
  // .woo-header{
  //   border-bottom: 1px solid rgba($red,.3);
  //   padding-bottom: 8px;
  //   h1{
  //     @include font-size-resp(70,1,-10);
  //   }
  // }
  // h2{
  //   @include font-size-resp(45,1,0);
  //   font-family: 'PassengerDisplay-Light-Italic';
  // }
  .shop{
    form:not(.ywpar_apply_discounts){
      border: none;
      @include font-size(17,22,0);
      border-radius: 0;
      background: $antiquewhite;
      margin: 1em 0 2em 0;
    }
    form.ywpar_apply_discounts{
      padding-top: 0!important;
    }
  }
}


@import "my-woocommerce/common";
@import "my-woocommerce/single-product";
@import "my-woocommerce/cart";
@import "my-woocommerce/account";
@import "my-woocommerce/checkout";
@import "my-woocommerce/order";
