.woocommerce.single-product{
  .woocommerce-error,
  .woocommerce-info,
  .woocommerce-message{
    margin: 30px 0 0 0;
  }

  .woocommerce-info a{
    color: $light-red;
    text-decoration: underline;
  }

  .single_add_to_cart_button{
    .spinner-border,
    .in-cart{
      display: none;
    }
    &.loading{
      .add{
        display: none;
      }
      .spinner-border{
        display: block;
        width: 25px;
        height: 25px;
      }
      .in-cart{
        display: none;
      }
    }
    &.added{
      .in-cart{
        display: block;
      }
      .add{
        display: none;
      }
    }
  }

  .added_to_cart{
    float: none;
    overflow: hidden;
    width: 100%;
    height: 55px;
    text-transform: uppercase;
    transition: $delay ease-in-out;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0;
    @include font-size(17,55,0);
    color: $white;
    background: $darkkhaki;
    &:hover {
      background-color: $darkolivegreen;
    }
  }

  .sidebar-ref{
    @include transition(all $delay);

    @include media-breakpoint-up(lg){
      top: 0;
      width: 90%;
      height: 100%;
      left: 5%;
    }

    &.no-sticky{
      @include media-breakpoint-up(lg){
        position: relative;
        width: 100%;
        left: auto;
      }
      .make-me-sticky{
        @include media-breakpoint-up(lg){
          position: relative;
          top: inherit;
          padding: 0 10px;
        }
      }
    }

    .make-me-sticky{

      @include media-breakpoint-up(lg){
        position: -webkit-sticky;
        position: sticky;
        top: 95px;
      }

      &.bottle{
        .product-image{
          @include media-breakpoint-up(lg){
            margin-top: -90px;
          }
        }
      }
      &.bib,
      &.canette{
        @include media-breakpoint-up(lg){
          top: 135px;
        }
        .product-image{
          @include media-breakpoint-up(lg){
            margin-top: -25px;
          }
          @include media-breakpoint-up(xl){
            margin-top: -47px;
          }
        }
      }

    }
    &.right{
      .make-me-sticky{
        @include media-breakpoint-up(lg){
          top: 145px;
        }
        @include media-breakpoint-up(xl){
          top: 175px;
        }
        hr{
          border-top: 1px solid $dimgray;
          margin-top: 20px;
          margin-bottom: 20px;
        }
        form.cart{
          display: none;
          @include media-breakpoint-up(lg){
            display: block;
          }
          .single_add_to_cart_button{
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  section{
    &.products,
    &.pro-space,
    &.our-stores,
    &.contact,
    &.newsletter,
    &.section-contact{
      z-index: 11;
    }
  }
  .mobile-cart{
    position: fixed;
    right: 0;
    left: 0;
    z-index: 10;
    bottom:0;
    @include media-breakpoint-up(lg){
      display: none;
    }
    .quantity_wrapper{
      float: left;
      width: 50%;
      margin-top: 10px;
    }
    .single_add_to_cart_button{
      float:right;
      margin-top: 0!important;
      width: 50%;
    }
  }
  /** NOTE : quickfix **/
  .seemore{
    .seemore-text{
      br{
        display: block;
        margin: 15px 0;
        content: " ";
      }
    }
  }
  /** NOTE : quickfix **/
}
