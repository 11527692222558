.agp__container{
  .agp__rowInner{
    padding: 30px !important;
  }
  .agp__row{

    @include vertical-align(relative);
    color: $white;

    .agp__rowInner{

      .agp__cancel{
        display: none !important;
      }

      .agp__message{
        @include font-size(17, 20,0);
        .msg{
          padding: 30px;
          border : solid 1px $white;
        }
      }

      .agp__buttonType__buttonContainer,
      .agp__retryButtonForm{
        input{
          border: none;
          text-transform: uppercase;
          padding: 0 30px;
          @include font-size(16, 50, 10);
          background: $white;
          color: $black;
          &:focus{
            outline: 0;
          }
          &:hover{
            background: $salmon;
            color: $white;
          }
        }
      }

      .agp__disclaimer{
        @include font-size(12, 15, 0);
        span{
          text-transform: uppercase;
        }
      }

    }

  }
}
