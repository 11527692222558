// paths
$font-path: '../fonts';
$svg-path: '../svg';
$img-path: '../images';

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

/*-----------------
  VARS
  -> paths
  -> colors
  -> fonts
  -> media queries
-----------------*/

// Spacing and padding
$spacer: 10px;
$spacers: ();
$spacers: map-merge((
  0: 0,
  1: ($spacer * .1),
  5: ($spacer/2),
  10: ($spacer),
  15: ($spacer * 1.5),
  20: ($spacer * 2),
  25: ($spacer * 2.5),
  30: ($spacer * 3),
  40: ($spacer * 4),
  45: ($spacer * 4.5),
  50: ($spacer * 5),
  60: ($spacer * 6),
  65: ($spacer * 6.5),
  70: ($spacer * 7),
  80: ($spacer * 8),
  90: ($spacer * 9),
  100: ($spacer * 10),
  110: ($spacer * 11),
  120: ($spacer * 12),
  130: ($spacer * 13),
  140: ($spacer * 14),
  150: ($spacer * 15),
  160: ($spacer * 16),
  170: ($spacer * 17),
  180: ($spacer * 18),
  190: ($spacer * 19),
  200: ($spacer * 20),
), $spacers);

// fonts
$font-family-base : "gesta",sans-serif;
// $font-family-alt : "freight-big-pro",serif;
$font-family-alt : "playfair-display", serif;
$default-font-family : $font-family-base;

.tk-freight-big-pro {
  font-family: "playfair-display", serif !important;
}

.tk-professor {
  font-family: "professor", sans-serif;
}

@font-face {
  font-family: 'AntiqueOlive';
  src: url('#{$font-path}/AntiqueOlive.ttf') format('truetype');
  /* Add additional formats if available */
}

.antique {
  font-family: 'AntiqueOlive', sans-serif;
}

$enable-responsive-font-sizes: true !default;
// $rfs-factor:100;
$rfs-base-font-size:13px;
$rfs-breakpoint: 1600px;

// Grid containers
$container-max-widths: (
  md: 720px,
  lg: 975px,
  xl: 1200px
);

// transitions
$delay: .3s;

$input-btn-focus-box-shadow: none;

// colors
$darkseagreen : #a9ba85;
$darkolivegreen: #63713a;
$salmon : #ee7961;
$indianred : #bc5d4d;
$antiquewhite : #ece6d6;
$tan : #d3c5a5;
$black : #111;
$white : #fff;
$brown : #762331;
$dimgray : #707070;
$light-red : #F1D9D9;
$light-grey : #eeebe5;
$darkkhaki : #9BAD70;

$mycolors: (darkseagreen $darkseagreen) (darkolivegreen $darkolivegreen) (salmon $salmon) (indianred $indianred) (antiquewhite $antiquewhite) (tan $tan) (black $black) (white $white) (brown $brown) (dimgray $dimgray) (darkkhaki $darkkhaki) (light-grey $light-grey);
