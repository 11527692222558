.woocommerce-breadcrumb{
  color: $white;
}
.post-type-archive-product,
.woocommerce-page{
  @include shop-argus();
  .woocommerce td.product-name dl.variation dt.variation-Dtail{
    display: none;
  }
  .page-header-title{
    padding-bottom: 30px;
    h1{
      margin-bottom: 0;
    }
  }
  #breadcrumbs,
  .page-header-title,
  .header,
  .header .navbar{
    background-color: $brown;
  }
  .woocommerce-notices-wrapper{
    .woocommerce-message{
      background-color: $brown;
      padding-left: 15px !important;
      padding-right: 15px !important;
      margin-bottom: 0;
    }
  }
  .wave-3{
    @include media-breakpoint-up(md){
    background: url('#{$img-path}/wave-brown.svg') center center repeat-x;
    }
    &:before{
      @include media-breakpoint-up(md){
        height: 170px;
      }
      @include media-breakpoint-up(lg){
        height: 50px;
      }
      background-color: $brown;
    }
  }
  section{
    &.products,
    &.pro-space,
    &.our-stores,
    &.contact,
    &.newsletter{
      z-index: 11;
    }
  }
  .shop{
    @include make-sticky();

    .wpgb-facet{
      &:not(.wpgb-facet-1):not(.wpgb-facet-7):not(.wpgb-facet-6){
        fieldset{
          display: none;
        }
        .wpgb-facet-title{
          cursor: pointer;
          &:after{
            right: -3px;
            top: 0;
            position: absolute;
            content: "\f347";
            border: none;
            font-family: dashicons;
            display: inline-block;
            line-height: 1;
            font-weight: 400;
            font-style: normal;
            speak: never;
            text-decoration: inherit;
            text-transform: none;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 22px;
            vertical-align: top;
            text-align: center;
          }
          // &.rotated{
          //   &:after{
          //     @include rotate(180deg);
          //   }
          // }
        }
      }
    }

    .wpgb-facet-title{
      text-transform: uppercase;
      margin-bottom: 20px;
      font-weight: 500;
      border-bottom: solid 1px $black;
    }
    .wpgb-facet{
      &.wpgb-facet-7{
        @include media-breakpoint-up(lg){
          -ms-flex-align: center!important;
          align-items: center!important;
          display: flex;
        }
        h4{
          @include media-breakpoint-up(lg){
            margin-bottom: 0 !important;
          }
          margin-right: 15px;
          text-transform: none;
          border-bottom: none;
        }
        fieldset{
          margin-bottom: 0 !important;
        }
      }
      &.wpgb-facet-1{
        .wpgb-input{
          background: $white;
          border: 0;
          border-radius: 0;
        }
      }
      &>fieldset:last-child{
        margin-bottom: 25px;
      }
      .wpgb-checkbox-facet{
        .wpgb-checkbox-control{
          background: $white;
          border-color: $white;
          border-radius: 0;
        }
      }
    }
  }
}


.woocommerce-checkout-review-order,
.woocommerce-cart,
.woocommerce-order-details,
.woocommerce-orders{

  // .woocommerce a.remove{
  //   &:after{
  //     content:"toto";
  //   }
  // }
  table{
    &.shop_table{
      @include font-size(17,20,0);
      border:none;
      margin-bottom: 0;
      // border-collapse: collapse;
      thead{
        background: $antiquewhite;
        color: $black;
        @include font-size(17,20,0);
      }
      tbody{
        background: $white;
      }
      td{
        border-top: 1px solid $light-grey;
        &.product-name{
          a{
            color: $black;
          }
        }
        &.actions{
          padding-top: 20px;
        }
      }
    }
    &.cart{
      td.actions .input-text{
        @include media-breakpoint-up(sm){
          width: 140px;
        }
      }
    }
  }
}

.woocommerce-customer-details{
  address{
    border-radius: 0 !important;
    border:0 !important;
    background-color: $light-grey !important;
  }
}

.woocommerce-billing-fields .form-row,
.woocommerce-shipping-fields .form-row,
.woocommerce form .form-row {
    display: block;
}

.woocommerce .col2-set .col-1,
.woocommerce-page .col2-set .col-1,
.woocommerce .col2-set .col-2,
.woocommerce-page .col2-set .col-2 {
    max-width: unset;
    h2{
      @include font-size(25,25,10);
      @include media-breakpoint-up(md){
        @include font-size(30,30,10);
      }
    }
}

.woocommerce-error,
.woocommerce-info,
.woocommerce-message{
  border-top: none !important;
  background: $white;
  color: $black;
}
.woocommerce-info,
.woocommerce-message{
  padding: 1em 2em 1em 2em !important;
  &:before{
    display: none !important;
  }
}

div.woocommerce{
  background: $brown;
  padding: 30px 0 50px 0;
  @include media-breakpoint-up(lg){
    padding: 50px 0 80px 0;
  }
}

.my-tooltip {
  position: relative;
  display: inline-block;
  .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }
  }
  &:hover{
    .tooltiptext {
      visibility: visible;
      opacity: 1;
    }
  }
}

#my-referral-link{
  padding: 5px;
  border-style:solid !important;
  width: 100%;
  @include media-breakpoint-up(lg){
    width: 50%;
  }
}
