.ranges-slider{
  .swiper-button-next, .swiper-button-prev{
    top: 10px;
  }
}
.swiper-pagination-bullet {
  width: 11px;
  height: 11px;
  margin: 0 3px;
}
.swiper-pagination-bullet-active{
  background: $black;
}
.swiper-slide{
  .left{
    border: solid 1px $black;
    h3{
      @include media-breakpoint-down(md){
        @include font-size(35,38,0);
      }
    }
  }
}
.featured-ranges,
.shop-slider{
  h2{
    br{
      @include media-breakpoint-down(lg){
        display: none;
      }
    }
  }
  &.wave-3{
    &:before{
      @include media-breakpoint-up(md){
        height: 170px;
      }
      @include media-breakpoint-up(lg){
        height: 50px;
      }
      background-color: $darkseagreen;
    }
  }
}
