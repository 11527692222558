 /* --- bootstrap-menu styling http://bootstrap-menu.com  */
body.offcanvas-active{
	overflow:hidden;
}
.navbar-collapse{
	&>*{
    position: initial;
	}
}
.offcanvas-header{ display:none; }

.screen-overlay {
  width:0%;
  height: 100%;
  z-index: 30;
  position: fixed;
  top: 0;
  left: 0;
  opacity:0;
  visibility:hidden;
  background-color: rgba(34, 34, 34, 0.6);
  transition:opacity .2s linear, visibility .1s, width 1s ease-in;
   }
.screen-overlay.show {
    transition:opacity .5s ease, width 0s;
    opacity:1;
    width:100%;
    visibility:visible;
}
.minify .navbar-hover .animate{
	margin-top: 25px;
}
.modal .modal-dialog-aside{
	width: 350px; max-width:80%; height: 100%; margin:0;
	transform: translate(0); transition: transform .2s;
}
.modal .modal-dialog-aside .modal-content{  height: inherit; border:0; border-radius: 0;}
.modal .modal-dialog-aside .modal-content .modal-body{ overflow-y: auto }
.modal.fixed-left .modal-dialog-aside{ margin-right:auto;  transform: translateX(-100%); }
.modal.fixed-right .modal-dialog-aside{ margin-left:auto; transform: translateX(100%); }

.modal.show .modal-dialog-aside{ transform: translateX(0);  }




// .dropdown-large, .megasubmenu, .megamenu{ padding:20px; }
.megamenu{
	padding: 40px 0;
}
.dropdown-menu .dropdown-menu{margin-left:0; margin-right: 0;  }
.dropdown-menu .dropdown-toggle:after{
	border-top: .3em solid transparent;
  border-right: 0;
  border-bottom: .3em solid transparent;
  border-left: .3em solid;
	@include media-breakpoint-down(md){
		top: 25px;
    right: 5px;
    position: absolute;
	}
}

.dropdown .dropdown-toggle:after{
	right: -10px;
  top: 13px;
	position: absolute;
	content: "\f347";
	border: none;
	font-family: dashicons;
  display: inline-block;
  line-height: 1;
  font-weight: 400;
  font-style: normal;
  speak: never;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  vertical-align: top;
  text-align: center;
}

.navbar{
	.icon-arrow:after{
		content:"\276F"; line-height: 1; display:inline-block; vertical-align: middle; margin-left:7px; margin-top:-2px; opacity:.3; font-size:80%;
	}
}


.dropdown.has-dimmer:after{
	content: ''; top: 0;  right: 0; bottom: 0; left: 0; transition: opacity .15s ease-in-out;
	opacity: 0; visibility:hidden; background-color: rgba(0,0,0,.5); pointer-events: none;
}
.navbar:not(.navbar-hover){
	.dropdown.show.has-dimmer:hover .nav-link{ position:relative; z-index:12; }
	.dropdown.show.has-dimmer:after{ z-index:10; opacity:1;  visibility:visible; position: fixed; }
}

.navbar{
	@include media-breakpoint-up(xl){
		padding-top: 20px;
		padding-bottom: 20px;
	}
}

/* ============ desktop view ============ */
@media all and (min-width: 992px) {

	.navbar{
		padding-top: 10px;
		padding-bottom: 10px;
		padding-right: 0;
		padding-left: 0;

		.has-megamenu{position:static!important;}
		.dropdown-menu{margin-top:0;}
		.megamenu{left:0; right:0; width:100%; /*padding:55px 20px; */ }
		.megasubmenu{ left:100%; top:0; min-height: 100%; min-width:500px;  }
		.dropdown-large{min-width:500px;}
		.menu-item{
			padding-right: 20px;
			a{
				transition: all $delay;
			}
			&:hover,
			&.current-page-parent,
			&.current-menu-item{
				a:not(.allow-link){
					color:$brown !important;
				}
			}
			&.current-page-parent,
			&.current-menu-item{
				a:not(.allow-link){
					font-weight: 500;
				}
			}
			&.shop{
				background: $white;
				a:not(.allow-link){
					color: $brown !important;
					font-weight: 500;
				}
				&:hover{
					background: $brown;
					a:not(.allow-link){
						color: $white !important;
					}
				}
			}
		}

		// .nav-link{ padding-top:1rem; padding-bottom:1rem;  }
	}

	.submenu, .dropdown-menu .dropdown-menu{
		display: none; position: absolute; 	left:100%; top:-7px;
		&.submenu-left{ right:100%; left:auto; }
	}

	.navbar-hover {
		.dropdown.has-dimmer:hover .nav-link{ position:relative; z-index:12; }
		.dropdown.has-dimmer:hover:after{ z-index:10; opacity:1;  visibility:visible; position: fixed; }

		&.navbar-dark{
			.nav-item:hover .nav-link{ color: #fff;  }
		}
		&.navbar-light{
			.nav-item:hover .nav-link{ color: #000;  }
		}

		.dropdown-menu:not(.animate) { display: none; }

		.animate{  display:block; opacity: 0;  visibility: hidden; transition:.4s; margin-top:0;  }
		.dropdown-menu .animate{ transition: .1s }

		.fade-down{ top:44px; transform: rotateX(-80deg); transform-origin: 0% 0%; }
		.fade-up{ top:100%; }
		// .dropdown-menu .fade-down{ top:0;}

		.nav-item:hover, .dropdown:hover{
			> .dropdown-menu{ display: block; transition: .4s; opacity:1; visibility:visible; /*top:120px; */transform: rotateX(0deg); }
		}
	}


	.dropdown-menu{
		> li:hover { background-color: #f1f1f1; }
		li{ position: relative; }
		li.has-megasubmenu{ position:static; }
	 	li:hover{
	 		> .submenu, > .dropdown-menu{ display: block; transition: .4s; opacity:1; visibility:visible; top:0%; transform: rotateX(0deg);  }
	 		> .megasubmenu{ display: block; transition: .4s; opacity:1; visibility:visible; top:0%; transform: rotateX(0deg);  }
	 	}
	}
}
/* ============ desktop view .end// ============ */


/* ============ small screen ============ */
@media all and (max-width:991px) {

	.offcanvas-header{ display:block; }

	.mobile-offcanvas{
		visibility: hidden;
		transform:translateX(-100%);
	    border-radius:0;
		display:block;
	    position: fixed;
	    top: 0; left:0;
	    height: 100%;
	    z-index: 1200;
	    width:80%;
	    overflow-y: scroll;
	    overflow-x: hidden;
	    transition: visibility .2s ease-in-out, transform .2s ease-in-out;
	    .container{ display:block; }
	}

	.mobile-offcanvas.show{
		visibility: visible;
    	transform: translateX(0);
	}
}
/* ============ small screen //end ============ */
