// h1, h2, h3, h4, h5, h6{
//   margin: 0;
// }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  @include font-size(14,26);
  font-weight: initial;
}

.regular{
  font-weight: 400;
}

.medium{
  font-weight: 500;
}

.semi{
  font-weight: 600;
}

.bold{
  font-weight: 700;
}

.black{
  font-weight: 900;
}

$sizes: 16,17,20,25,28,30,35,40,55,60,75,85;

@each $size in $sizes {
  .f-#{$size} {
    @include font-size-resp($size,1.1,0);
  }
}

.f-36 {
  font-family: $font-family-alt;
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
}
