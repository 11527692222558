$modal-background: $white;
$modal-background-opacity: .8;
$cookie-law-info-bar-border-color: $darkolivegreen;
$cookie-law-info-bar-bg-color: $darkolivegreen;
$cookie-law-main-text-color: $white;
$cookie-law-info-again-bg-color: $tan;
$cookie-law-info-again-text-color: $black;
// Accept button
$cookie-action-close-header-bg-color: $salmon;
$cookie-action-close-header-text-color:$white;
// Reject button
$cookie-action-close-header-reject-bg-color: $dimgray;
$cookie-action-close-header-reject-text-color: $white;
//Settings button
$cli-settings-button-bg-color: $light-grey;
$cli-settings-button-text-color: $black;
//Main link
$cli-plugin-main-link: $white;

.cli-modal-backdrop{
  background: $modal-background;
  opacity: $modal-background-opacity !important;
}
.wt-cli-cookie-bar-container{
  z-index: 15000;
  font-family: $default-font-family;
  #cookie-law-info-bar{
    box-shadow: none !important;
    border: solid 1px $cookie-law-info-bar-border-color !important;
    background: $cookie-law-info-bar-bg-color !important;
    padding: 30px !important;
    span{
      color: $cookie-law-main-text-color !important;
    }
  }
  .cli_settings_button{
    border: none !important;
  }
  #cookie-law-info-again{
    box-shadow: none !important;
    background: $cookie-law-info-again-bg-color !important;
    color: $cookie-law-info-again-text-color !important;
    left: auto!important;
    right:0;
    @include media-breakpoint-down(md){
      display: none !important;
    }
  }
  .cli_settings_button,
  .cookie_action_close_header_reject{
    @include media-breakpoint-down(sm){
      width: 100% !important;
    }
  }
}

.cli-plugin-main-link{
  color: $cli-plugin-main-link !important;
  font-weight: 400 !important;
}

.cookie_action_close_header{
  border-radius: 0 !important;
  background: $cookie-action-close-header-bg-color !important;
  color: $cookie-action-close-header-text-color !important;
}

.cookie_action_close_header_reject{
  border-radius: 0 !important;
  background: $cookie-action-close-header-reject-bg-color !important;
  color: $cookie-action-close-header-reject-text-color !important;
}

.cli_settings_button{
  border-radius: 0 !important;
  background: $cli-settings-button-bg-color !important;
  color: $cli-settings-button-text-color !important;
  font-size: 15px !important;
  float:right;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.cli-plugin-main-button{
  font-weight: 400!important;
}

.cli_action_button{
  float:left;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
