.header{
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1031;
  box-shadow: 0 1px 25px rgba(0,0,0,.1);
  .additional-links{
    a{
      transition: color $delay;
      &:hover{
		  	color:$brown !important;
			}
    }
  }
  .logo-univitis{
    width: 117px;
    // height: 35px;
    @include media-breakpoint-up(md){
      width: 151px;
      // height: 45px;
    }
  }
  .main-link{
    @include font-size(20,24,0);
  }
  .navbar-toggler{
    padding: 0;
    border: 0;
    font-size: 19px;
    margin-left: 6px;
    padding-top: 2px;
    .navbar-toggler-icon{
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }
  }
  .navbar-collapse{
    @include media-breakpoint-down(md){
      height: 100vh;
      overflow-y: auto;
      padding-bottom: 150px;
      .nav-item{
        a.nav-link{
          @include font-size(16,40,0);
          border-bottom: solid 1px $white;
        }
      }

      .dropdown-toggle{
        &:after{
      		top: 25px;
          right: 5px;
          position: absolute;
        }
      }
      .dropdown-menu{
        padding: 0;
        background-color: transparent;
        .row{
          margin: 0;
          [class*=col-]{
            padding: 0;
          }
        }
      }
    }
  }

  .centered-logo{
    @include media-breakpoint-up(lg){
      @include horizontal-align(absolute);
    }
  }

  .wpml{
    min-height: 43px;
    @media (max-width: 369px){
      position: fixed;
      left: 123px;
      top: 6px;
      z-index: 1;
    }
    .wpml-ls{
      // position: absolute;
      // right: 30px;
      // top: 22px;
      // background: $white;
      // z-index: 1;
      // @include media-breakpoint-up(md){
      //   right: 35px;
      //   top: 30px;
      // }
      // @include media-breakpoint-up(lg){
      //   right: 0;
      //   top: 5px;
      // }
      // width: 60px;
      a{
        // background: transparent;
        // border: 0!important;
        padding: 5px;
      }
      .wpml-ls-current-language:hover>a{
        // background: transparent;
      }
      a:focus,
      a:hover{
        background: transparent;
      }
      .wpml-ls-flag{
        // border-radius: 50%;
        // border: solid 1px $gold;
      }
      a.wpml-ls-item-toggle{
        z-index: 1;
      }
    }
  }

  .logo-wrapper{
    border-bottom: solid 1px $white;
    padding-top: 10px;
    padding-bottom: 15px;
  }

  .navbar-nav{
    .nav-item{

        .sub-shop{
          @include media-breakpoint-down(md){
            padding: 0;
          }
          .sub{
            border-bottom:  solid 1px $white;
            padding-bottom: 5px;
          }
          .shop-title{
            a{
              @include font-size(19,20,0);
              color: $white;
              &:hover{
                color:$darkseagreen !important;
              }
              @include media-breakpoint-down(md){
                @include font-size(16,40,0);
                font-weight: 400;
                &:before{
                  content: "> ";
                  font-size: 20px;
                }
              }
            }
          }
          ul{
            list-style: none;
            margin-top: 5px;
            li{
              border-bottom: solid 2px rgba(#06502D,.2);
              a{
                font-weight: 400 !important;
                @include font-size(17,35,0);
                @include media-breakpoint-down(md){
                  @include font-size(16,40,0);
                  color: $white;
                  &:before{
                    content: "> ";
                    font-size: 20px;
                  }
                }
                &:hover{
                  color:$darkseagreen !important;
                }
              }
            }
          }
          .btn-huge{
            border: solid 1px $white;
            padding: 30px;
            @include media-breakpoint-only(md){
              width: 48%;
            }
            @include media-breakpoint-up(lg){
              padding: 60px;
            }
            color: $white;
            .svg{
              svg{
                transition: 0.5s ease-in-out;
                fill:$white;
              }
            }
            @include media-breakpoint-up(lg){
              &:hover {
                color: $darkseagreen !important;
                border: solid 1px $darkseagreen;
                .svg{
                  svg{
                    fill: $darkseagreen;
                  }
                }
              }
            }
            div.text-center{
              @include media-breakpoint-down(md){
                text-align: left !important;
                span{
                  display: inline !important;
                }
              }
            }
          }
        }
      .dropdown-menu{
        border: 0;
        border-radius: 0;
        @include media-breakpoint-up(lg){
          background: $darkolivegreen;
          &:before{
            content: "";
            left: -100%;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            background: $darkolivegreen;
          }
          &:after{
            content: "";
            right: -100%;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            background: $darkolivegreen;
          }
        }
        a{
          &:hover{
            text-decoration: none;
          }
        }
        .col-megamenu{
          position: relative;
          a{
            border-bottom: solid 1px $white;
            @include media-breakpoint-up(lg){
            }
            @include media-breakpoint-down(md){
              @include font-size(16,40,0);
              display: block;
              padding: 0.5rem 0 0.5rem 9px;
              &:before{
                content: "> ";
                font-size: 20px;
              }
            }
            &:hover{
              color:$darkseagreen !important;
              border-bottom: solid 1px $darkseagreen;
            }
          }
          &.with-image{
            .title{
              @include media-breakpoint-up(lg){
                position: absolute;
                bottom: 0;
                left: 0;
                opacity: 0.7;
                background-color: $darkolivegreen;
                height: 50px;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.single-gammes,
.post-type-archive-product,
.single-product,
.woocommerce-page{
  @include media-breakpoint-up(lg){
    .navbar{
      .menu-item{
        &:hover,
        &.current-page-parent,
        &.current-menu-item{
          &:not(.shop){
            a:not(.allow-link){
              color:$white !important;
            }
          }
        }
      }
    }
    .additional-links{
      a{
        &:hover{
          color:$white !important;
          text-decoration: underline;
        }
      }
    }
  }
}
