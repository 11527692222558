@import "pages/single-job";

.single-share{
  position: fixed;
  bottom: 1%;
  max-width: 300px;
  right: 1%;
  z-index: 10;
  @include transition(all $delay);
  @include media-breakpoint-up(lg){
    z-index: 1;
    top: 140px;
    bottom: initial;
    right: -20px;
  }
  @include media-breakpoint-up(xl){
    right: 1%;
  }

  .btn-back{
    @include media-breakpoint-down(md){
      display: none !important;
    }
  }

  .social{
    @include media-breakpoint-down(md){
      @include transition(all $delay);
      opacity: 1;
    }
    .share-text{
      @include media-breakpoint-up(lg){
        @include rotate(-90deg);
        color:$blue;
      }
      @include media-breakpoint-down(md){
        color: $white;
        @include transition(all $delay);
      }
      .text{
        @include media-breakpoint-down(md){
          @include transition(all $delay);
        }
      }

      @include media-breakpoint-down(md){
        .share{
          display: block;
        }
        .social-close{
          display: none;
        }
        &.active{
          .share{
            display: none;
          }
          .social-close{
            display: block;
          }
        }
      }

    }
    .social-items{
      a{
        margin: 10px 0;
        svg{
          @include transition(all $delay);
        }
        &:hover{
          svg{
            transform: scale(1.1);
          }
        }
      }
      @include media-breakpoint-down(md){
        display: none;
      }
    }

    @include media-breakpoint-down(md){
      background: $brown;
      border-radius: 100px;
      padding: 2px 15px;
      height: 60px;
    }

  }

}

.single-post{
  #breadcrumbs,
  .header,
  .header .navbar,
  .page-header-title{
    background-color: $salmon;
  }
  .wave-3{
    &:before{
      background: $salmon !important;
    }
    @include media-breakpoint-up(md){
      background: url('#{$img-path}/wave-salmon.svg') center center repeat-x;
    }
  }
  @include make-sticky();
  .sidebar-ref{
    .card{
      flex: 0 0 calc(100% - 30px);
    }
  }
  .left-col{
    h3{
      margin-bottom: 30px;
      @include media-breakpoint-up(lg){
        margin-bottom: 40px;
      }
    }
  }
}

.single-gammes{
  .wave-3{
    @include media-breakpoint-up(md){
      background: url('#{$img-path}/wave-brown.svg') center center repeat-x;
    }
    &:before{
      background: $brown;
    }
  }

  .page-header-title{
    padding-bottom: 30px;
  }

  #breadcrumbs,
  .header,
  .header .navbar,
  .page-header-title{
    background-color: $indianred;
  }

  @include media-breakpoint-up(lg){
    .navbar{
      .menu-item{
  			&:hover,
  			&.current-page-parent,
  			&.current-menu-item{
  				a:not(.allow-link){
  					color:$white !important;
  				}
  			}
      }
    }
  }

  .page-header {
    &::after {
      content: none;

      @include media-breakpoint-up(lg) {
        content: "";
        background-color: $white;
        left: 0;
      }
    }
  }

  .section-contact::after {
    content: none;

    @include media-breakpoint-up(lg) {
      content: "";
    }
  }

  .page-header-img {
    aspect-ratio: 140 / 55;
    height: auto;
    object-fit: cover;
    max-height: 550px;
    width: 100%;
  }

  .range-presentation{
    .icons{
      margin-top: -35px;
      @include media-breakpoint-up(md){
        margin-top: -50px;
      }
    }
  }

  .presentation-gamme-grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr);

    @include media-breakpoint-up(lg) {
      column-gap: 40px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .presentation-surtitle {
      font-size: 1rem;
      font-weight: 700;
      margin-bottom: 10px;
      text-transform: uppercase;

      @include media-breakpoint-up(lg) {
        font-size: 30px;
      }
    }

    .presentation-title {
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 1.5rem;

      @include media-breakpoint-up(lg) {
        font-size: 85px;
        line-height: 70px;
      }
    }
  }

  .gamme-img {
    align-self: center;
    aspect-ratio: 520 / 465;
    height: auto;
    object-fit: cover;
    width: 100%;
  }

  .top-presentation-wrapper {
    column-gap: 1rem;
    display: flex;
    margin-bottom: 25px;

    @include media-breakpoint-up(lg) {
      column-gap: 30px;
    }
  }

  .presentation-images-grid {
    padding-block: 50px;

    @include media-breakpoint-up(lg) {
      padding-block: 50px 95px;
    }
  }

  .presentation-img,
  .ambition-img {
    height: auto;
    max-width: 570px;
    object-fit: cover;
    width: 100%;
  }

  .third-presentation-img {
    height: auto;
    max-height: 570px;
    object-fit: cover;
    width: 100%;
  }

  .gammes-slider {
    margin-inline: auto;
    max-width: 930px;
  }

  .slider-bottles-wrapper {
    padding-block: 60px 45px;
  }

  .swiper-slide {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    transition: all .3s ease;

    &:not(.swiper-slide-active) {
      @include media-breakpoint-up(lg) {
        transform: scale(0.8);
      }

      .slide-content {
        display: none;
      }
    }

    .gamme-title {
      font-size: 20px;
      margin-bottom: 10px;
    }

    .gamme-color {
      border-bottom: 1px solid $dimgray;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 20px;
      margin-inline: auto;
      padding-bottom: 20px;
      text-transform: uppercase;
      width: 170px;
    }

    .gamme-desc {
      text-align: left;
    }

    .discover-btn {
      align-items: center;
      background-color: $black;
      color: #fff;
      display: flex;
      justify-content: center;
      margin-inline: auto;
      margin-top: 20px;
      padding: 1rem 2rem;
      text-transform: uppercase;
      width: 235px;
    }
  }

  .slide-content {
    background-color: $white;
    display: block;
    height: auto;
    max-width: 310px;
    padding-block: 20px 2rem;
    padding-inline: 30px;
    transform: translateY(-2rem);
  }

  .ambition-wrapper {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    padding-block: 50px;
    row-gap: 20px;

    @include media-breakpoint-up(md) {
      column-gap: 30px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media-breakpoint-up(lg) {
      padding-block: 140px;
    }
  }

  .ambition-content {
    h2 {
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 20px;

      @include media-breakpoint-up(lg) {
        font-size: 75px;
        margin-bottom: 40px;
      }
    }

    p {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .key-numbers {
    background-color: $salmon;
    padding-block: 60px 90px;

    .top-wrapper {
      align-items: flex-start;
      display: flex;
      column-gap: 30px;
    }

    h2 {
      color: $white;
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 30px;
      text-wrap: nowrap;

      @include media-breakpoint-up(lg) {
        font-size: 75px;
        margin-bottom: 60px;
      }
    }

    .white-line {
      border-bottom: 1px solid $white;
      display: block;
      height: 1px;
      width: 100%;
    }

    .key-numbers-wrapper {
      display: flex;
      flex-direction: column;
      font-size: 20px;
      font-weight: 700;
      list-style: none;
      row-gap: 30px;

      @include media-breakpoint-up(lg) {
        align-items: center;
        flex-direction: row;
        font-size: 50px;
        line-height: 50px;
        justify-content: space-around;

      }

      li {
        align-items: center;
        display: flex;
        flex-direction: column;
          position: relative;
      }

      .number,
      .key-category {
        z-index: 2;
      }

      .line {
        bottom: 0;
        position: absolute;
        top: -25%;
      }
    }
  }

  .section-contact::after {
    background-color: $indianred;
  }
}

.single-product{
  background: $antiquewhite;
  #breadcrumbs,
  .header,
  .header .navbar{
    background-color: $brown;
  }
  .wave{
    &:before{
      @include media-breakpoint-up(md){
        background: url('#{$img-path}/wave-brown.svg') center center repeat-x;
        height: 260px;
        content: "";
        width: 100%;
        position: absolute;
        top: 60px;
        z-index: 1;
      }
      @include media-breakpoint-down(sm){
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 200px;
        background: $brown;
        clip-path: ellipse(75% 100% at 50% 0%);
        -webkit-clip-path: ellipse(75% 100% at 50% 0);
      }
    }
  }
  .single-product-details{
    z-index: 2;
  }
  .single-product-details{
    .product-image{
      img{
        @include media-breakpoint-down(md){
          max-height: 390px;
          width: auto;
        }
        @include media-breakpoint-only(md){
          max-height: 540px;
          width: auto;
        }
      }
      &:before{
        background: $white;
        content: "";
        border-radius: 60px;
        border-top-right-radius: 0;
        height: 75%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 13%;
        @include media-breakpoint-down(sm){
          border-radius: 30px;
          border-top-right-radius: 0;
          height: 83%;
          top:8%;
        }
      }
    }
  }
  .list{
    li{
      hr{
        border-top: 1px solid $dimgray;
      }
      &:last-child{
        hr{
          display: none;
        }
      }
    }
  }
  .price{
    .amount{
      @include font-size(30,24,0);
    }
  }
}
