// .btn-book{
//   @include font-size(12,14,100);
//   height: 80px;
//   width: 100%;
//   max-width: 100%;
//   @include transition(all $delay);
//   font-family: $font-family-base;
//   font-weight: 600;
//   color: $white;
//   background: $light-red;
//   border: solid 1px $white;
//   text-transform: uppercase;
//   &:hover {
//     background: $white;
//     border-color: $light-red;
//     color: $light-red;
//   }
// }


//https://codepen.io/kjbrum/pen/wBBLXx
[class*=btn-] {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 55px;
  text-transform: uppercase;
  transition: $delay ease-in-out;
  @include font-size(17,20,0);
  &*:before,
  &*:after {
    box-sizing: border-box;
    transition: $delay ease-in-out;
  }
  div{
    position: relative;
  }
  &:hover{
    text-decoration: none;
  }
  &.mob-100{
    @include media-breakpoint-down(sm){
      max-width: 100%;
    }
    @include media-breakpoint-only(md){
      max-width: 55%;
    }
  }
}

.btn-default,.btn-svg {
  .svg{
    svg{
      transition: 0.5s ease-in-out;
    }
    &.rotate{
      @include rotate(180deg);
    }
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    transform: translateY(100%);
  }
  &:hover {
    &:before {
      transform: translateY(0);
    }
    .svg{
      svg{
        fill: $white !important;
      }
    }
  }

  &.btn-bg-indianred{
    color: $white;
    background: $indianred;
    border: 1px solid $indianred;
    &:before {
      background-color: rgba($indianred,.5);
    }
    .svg{
      svg{
        fill: $white !important;
      }
    }
    &:hover {
      .svg{
        svg{
          fill: $white !important;
        }
      }
    }
  }

  &.btn-bg-black{
    color: $white;
    background: $black;
    border: 1px solid $black;
    &:before {
      background-color: #333333;
    }
    .svg{
      svg{
        fill: $white !important;
      }
    }
    &:hover {
      .svg{
        svg{
          fill: $white !important;
        }
      }
    }
  }

  &.btn-bg-white{
    color: $black;
    background: $white;
    border: 1px solid $black;
    &:before {
      background-color: $black;
    }
    .svg{
      svg{
        fill: $black !important;
      }
    }
    &:hover {
      color: $white;
      .svg{
        svg{
          fill: $white !important;
        }
      }
    }
  }

  &.btn-add-to-cart{
    color: $white;
    background: $indianred;
    border: 1px solid $indianred;
    text-transform: none;
    &:before {
      background-color: $salmon;
    }
    .svg{
      svg{
        fill: $white;
      }
    }
    &:hover {
      color: $white;
      border: 1px solid $salmon;
      .svg{
        svg{
          fill: $white !important;
        }
      }
    }
  }

  &.btn-load-more{
    color: $white;
    // background: $black;
    @include media-breakpoint-down(sm){
      max-width: 100%;
    }
    // &:before {
    //   background-color:$red;
    // }
    &:hover {
      color: $white;
    }
    .spinner-border{
      display: none;
    }
    &.active{
      .spinner-border{
        display: block;
      }
      .text{
        display: none;
      }
    }
  }
}

.car-link {
  padding: 0 5px;
  font-weight: 600;
  position: relative;
  text-decoration: none;
  font-family: $font-family-alt;
  color: $black;
  @include transition(color $delay);
  &::after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    right: 0;
    bottom: 1px;
    height: 7px;
    background-color: $antiquewhite;
    @include transition(all $delay);
    z-index: 1;
    border-radius: 10px;
  }
  div{
    z-index: 2;
  }


  &:hover {
    text-decoration: none;
    color: $black;
    &::after {
      bottom: 0;
      height: 100%;
    }
  }

  &.red{
    &::after {
      background-color: #E3614D;
    }
  }

  &.white{
    &::after {
      background-color: #9BAD70;
    }
  }

  &.red,
  &.white{
    &:hover {
      color: $white;
    }
  }

  &.news{
    &:after {
      background-color: $white;
    }
    &:hover {
      &:after {
        background-color: $tan;
      }
    }
    &.cat-active{
      cursor: default;
      &::after {
        bottom: 0;
        height: 100%;
        background-color: $tan;
      }
    }
  }
}

.btn-book{
  @include font-size(17,50,0);
  height: 50px;
  width: 100%;
  max-width: 100%;
  @include transition(all $delay);
  font-family: $font-family-base;
  font-weight: 600;
  color: $white;
  background: $darkkhaki;
  border: solid 1px $darkkhaki;
  text-transform: uppercase;
  &:hover {
    background: $white;
    color: $darkkhaki;
  }
}

.delete-filters{
  color: rgba($black,.5);
  &:hover{
    color: rgba($black,.5);
    text-decoration: underline;
  }
}
