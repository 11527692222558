.woocommerce-cart{
  @include make-sticky();

  .cart-collaterals {
    .cart_totals {
      width: 100%;
      z-index: 1;
    }
    table.shop_table td{
      padding-left: 0;
      padding-right: 0;
    }
    .woocommerce-shipping-calculator{
      background: transparent !important;
      a{
        color: $brown;
        &:after{
          display: none;
        }
        &:hover{
          text-decoration: underline;
        }
      }
    }
    ul#shipping_method{
      li{
        border-bottom: 1px solid #ebe9eb;
        margin-bottom: 10px;
        padding-bottom: 10px;
      }
    }
    .free-shipping-message {
      color: $salmon;
      font-weight: 500;
    }
  }

  .woocommerce-cart-form{
    margin-top: 0 !important;
    table.cart .product-thumbnail { display:none; }
  }


  .button[name="apply_coupon"],
  .button[name="update_cart"]{
    &:not([disabled]){
      @include wooButtonDefault();
    }
    &:disabled{
      border:0;
    }
  }

  .cart_item{
    .product-price,
    .product-quantity,
    .product-subtotal {
      text-align: center;
    }
  }

}
