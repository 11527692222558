.popin-promo,
.popin-fidelity-program,
.popin-christmas-home,
.popin-christmas-shop {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  opacity: 0;
  z-index: 9998;
  visibility: hidden;

  .popin-promo-background,
  .popin-christmas-home-background,
  .popin-christmas-shop-background {
    height: 100%;
    width: 100%;
    background: $black;
    opacity: 0.5;
  }

  .popin-promo-container,
  .popin-fidelity-program-container,
  .popin-christmas-home-container,
  .popin-christmas-shop-container {
    position: absolute;
    // top: 25%;
    top: 50%;
    transform: translateY(-50%);
    // left: 50%;
    // -webkit-transform: translate(-50%, -50%);
    // transform: translate(-50%, 0);
    width: 100%;
    .container{
        max-width: 700px;
    }
    .closePopin,
    .closeChristmas,
    .closeChristmasShop {
        position: absolute;
      top: 5px;
      right: 5px;
      cursor : pointer;
    }
  }
  &.active {
    opacity: 1;
    visibility: visible;
  }
  @include media-breakpoint-down(sm){
    display: none;
  }
}

.popin-fidelity-program {
  .promo-text {
    border: 1px solid $white;
    padding: 30px;
  }

  .logo-univitis {
    width: 150px;
  }

  .popin-fidelity-program-background {
    height: 100%;
    width: 100%;
    background: $white;
    opacity: 0.8;

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
}

.bottle-shop {
  position: absolute;
  bottom: 0;
  right: 15%;

  @include media-breakpoint-down(sm) {
    max-height: 330px !important;
    right: 0;
    z-index: 11;
  }

  @include media-breakpoint-up(sm) {
    z-index: 9999;
  }
}

.popin-christmas-home,
.popin-christmas-shop {
  @include media-breakpoint-down(sm){
    display: block;
  }
}

.calendar {
  font-size: 30px; 
  transform: translateY(8px);

  @include media-breakpoint-down(sm) {
    font-size: 21px;
  }
}

