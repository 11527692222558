footer{
  &.footer{
    @include media-breakpoint-down(sm){
      z-index: 1035;
    }
    a{
      color: $black;
    }
    @include logo-univitis;
    p{
      @include font-size(13,18,0);
    }
    ul{
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-flow: column;
      li{
        @include font-size(17,24,0);
        a{
          @include font-size(17,24,0);
          &:hover{
            text-decoration: underline;
          }
        }
        &.main-item{
          font-weight: 500;
          @include media-breakpoint-down(sm){
            margin-top: 10px;
          }
        }
        &.footer-item-2416,
        &.footer-item-3090{
          a{
            color: $brown;
          }
        }
      }
    }
    .col-lg-3 {
      border-right: 1px solid #000;
      &:last-child{
        border: 0;
      }
      @include media-breakpoint-only(md){
        &:first-child{
          border: 0;
        }
      }
      @include media-breakpoint-down(sm){
        border: 0;
      }
    }
  }
}


.search-overlay{
  z-index: 16000;
  @include transition(right 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940));
  position: fixed;
  background-color: $brown;
  top: 0;
  width: 100%;
  height: 100%;
  right: -100%;
  @include logo-univitis;
  .search-wrapper{
    div#ajaxsearchpro1_1{
      background: transparent;
      .probox{
        @include media-breakpoint-down(sm){
          height: 50px !important;
        }
        height: 100px;
        border: none;
        .proloading{
          @include media-breakpoint-up(lg){
            width: 40px;
            height: 40px;
            min-width: 40px;
            min-height: 40px;
            max-width: 40px;
            max-height: 40px;
          }
          top:13px;
          .asp_loader{
            @include media-breakpoint-up(lg){
              width: 40px;
              height: 40px;

            }
          }
        }
        .promagnifier{
          display: none;
        }
        .proinput{
          padding-left: 15px;
          margin-left: 0;
          font-family: $font-family-alt;
          @include font-size(60,60,-30);
          @include media-breakpoint-down(sm){
            @include font-size(26,31,-30);
            max-height: 40px !important;
          }
          color: rgba($white,.3);
          input.autocomplete{
            display: none;
          }
          input.orig{
            font-family: $font-family-alt;
            @include font-size(60,70,-30);
            @include media-breakpoint-down(sm){
              @include font-size(26,40,-30);
              height: 40px;
              padding-left: 3px !important;
            }
            color: rgba($white,1);
            height: 70px;
            padding: 0 !important;
            @include placeholder(){
              font-family: $font-family-alt;
              @include font-size(60,70,-30);
              @include media-breakpoint-down(sm){
                @include font-size(26,40,-30);
              }
              color: rgba($white,.3);
              height: 100px;
            }
            &:focus::placeholder {
              color: transparent;
            }
          }
          input.orig{
            border-bottom: solid 1px $white;
            -webkit-appearance: none !important;
            -moz-appearance: none !important;
            appearance: none !important;
            border-radius: 0 !important;
          }
        }
      }
    }

  }

}

body{
  &.search-active{
    overflow: hidden;
    .search-overlay{
      right:0;
    }
  }
  div.asp_r.asp_r_1.vertical#ajaxsearchprores1_1{
    background: transparent;
    z-index: 17000;
    .results{
      padding-right: 30px;
      max-height: 60vh;
      @include scrollbars(3px, $black, rgba($black,.2));
      .item{
        background: transparent;
        border: 0;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 0;
        .asp_content{
          padding-left: 0;
          h3{
            @include media-breakpoint-down(sm){
              display: block;
              margin-bottom: 10px;
            }
            a{
              text-transform: uppercase;
              font-family: $default-font-family;
              color: $white;
              font-weight: 400;
              @include font-size(16,20,0);
            }
         }
          .asp_res_text{
            // p{
              @include font-size(13,15,-30);
              font-family: $default-font-family;
              color: $white;
            // }
          }
          &:hover{
            cursor: pointer;
            h3,.asp_res_text,.asp_res_text p{
              color: $white !important;
              text-decoration: underline;
            }
          }
        }
        .asp_image{
          width: 170px;
          height: 110px;
          margin-right: 30px;
          @include media-breakpoint-down(sm){
            float: none;
            margin-bottom: 15px;
            margin-right: 0;
          }
        }
        &:after{
          background: $white;
        }
      }
    }
  }
}
